import { ApiMediaFragment } from 'gql/api/api-fragments.generated';

import { PreviewMediaAsset } from 'types/media';

import { mapImgixGifToMp4, mapImgixVectorToRaster } from './imgix';

/**
 * casts GIFs to MP4s, 3D to IMGs & optimizes image assets
 */
export function mapApiMediaToPreviewMediaAsset(
  media: ApiMediaFragment | null
): PreviewMediaAsset | null {
  if (!media) {
    return null;
  }

  if (media.__typename === 'ModelMedia') {
    return {
      type: 'image',
      src: media.modelStaticUrl,
    };
  }

  if (media.__typename === 'VideoMedia') {
    return {
      type: 'video',
      src: media.previewUrl ?? media.url,
      poster: media.staticUrl ?? '',
    };
  }

  if (media.imageMimeType === 'IMAGE_GIF') {
    const video = mapImgixGifToMp4(media.url);
    return {
      type: 'video',
      src: video.src,
      poster: video.poster,
    };
  }

  if (media.imageMimeType === 'IMAGE_SVG') {
    return {
      type: 'vector',
      src: media.url,
      raster: mapImgixVectorToRaster(media.url),
    };
  }

  return {
    type: 'image',
    src: media.url,
  };
}
