import FrameGridItem, {
  FrameGridItemSelectableConfig,
  SortBadgeConfig,
} from 'components/FrameGridItem';
import FrameGridItemDetails, {
  FrameGridPresentedByWorld,
} from 'components/FrameGridItemDetails';
import MediaStack, { mapToLaunchMediaAsset } from 'components/MediaStack';
import Box from 'components/base/Box';

import { formatMintPrice } from 'utils/formatters';
import { mapApiMediaToLaunchMedia } from 'utils/media';
import { getPath } from 'utils/router';

import { Edition, EditionWithLegacyAsset } from 'types/Edition';
import { EditionCollectionSale } from 'types/EditionSale';
import { LaunchMediaAsset, OptimizeImageOptions } from 'types/media';

import BrandBadge from './BrandBadge';
import { EditionGridItemDynamicLabel } from './EditionGridItemDynamicLabel';

type EditionGridItemScaffoldingProps = {
  dynamicLabel: React.ReactNode | null;
  edition: Omit<Edition, 'media'>;
  enableLink?: boolean;
  media: React.ReactNode;
  showBrandBadge: boolean;
  sortBadge?: SortBadgeConfig;
  status: React.ReactNode;
  world?: FrameGridPresentedByWorld;
};

function EditionGridItemScaffolding(props: EditionGridItemScaffoldingProps) {
  const {
    dynamicLabel,
    edition,
    enableLink = true,
    media,
    showBrandBadge,
    sortBadge,
    status,
    world,
  } = props;

  return (
    <FrameGridItem
      href={enableLink ? getPath.mint.page(edition) : undefined}
      media={media}
      details={
        <FrameGridItemDetails
          world={world}
          dynamicLabel={dynamicLabel}
          name={edition.name}
          status={status}
          tag={
            showBrandBadge ? <BrandBadge product="editionCollection" /> : null
          }
          user={edition.creator}
        />
      }
      sortBadge={sortBadge}
    />
  );
}

type EditionGridItemProps = {
  sortBadge?: SortBadgeConfig;
  world?: FrameGridPresentedByWorld;
  sale: EditionCollectionSale;
} & (
  | {
      // TODO: phase out this version, in favor of the media version
      edition: EditionWithLegacyAsset;
      version: 'legacy';
    }
  | { edition: Edition; version?: 'media' }
);

function EditionGridItem(props: EditionGridItemProps) {
  const { edition, sale, sortBadge, world } = props;

  const getStackMedia = (): LaunchMediaAsset | null => {
    const imageOptions: OptimizeImageOptions = { q: 70, h: 640, w: 640 };

    if (props.version === 'legacy') {
      const { assetUrl, mimeType } = props.edition;
      return mapToLaunchMediaAsset({ assetUrl, mimeType }, { imageOptions });
    } else {
      const { media } = props.edition;
      return mapApiMediaToLaunchMedia(media, {
        imageOptions,
      });
    }
  };

  const stackMedia = getStackMedia();

  return (
    <EditionGridItemScaffolding
      dynamicLabel={
        <EditionGridItemDynamicLabel nftCount={edition.nftCount} sale={sale} />
      }
      enableLink
      edition={edition}
      media={
        <Box
          css={{
            padding: '8%',
          }}
        >
          {stackMedia && (
            <MediaStack
              color="$black5-solid"
              stackCount={2}
              media={stackMedia}
            />
          )}
        </Box>
      }
      showBrandBadge
      world={world}
      sortBadge={sortBadge}
      status={<EditionGridItemStatus mintPrice={sale.mintPrice} />}
    />
  );
}

function EditionGridItemStatus(props: { mintPrice: number }) {
  return (
    <FrameGridItemDetails.StatusRow>
      <FrameGridItemDetails.StatusText>
        {formatMintPrice(props.mintPrice)}
      </FrameGridItemDetails.StatusText>
    </FrameGridItemDetails.StatusRow>
  );
}

type EditionGridItemSelectableProps = FrameGridItemSelectableConfig & {
  sale: EditionCollectionSale;
} & (
    | {
        // TODO: phase out this version, in favor of the media version
        edition: EditionWithLegacyAsset;
        version: 'legacy';
      }
    | { edition: Edition; version?: 'media' }
  );

function EditionGridItemSelectable(props: EditionGridItemSelectableProps) {
  const { edition, sale } = props;

  const getStackMedia = (): LaunchMediaAsset | null => {
    const imageOptions: OptimizeImageOptions = { q: 70, h: 640, w: 640 };

    if (props.version === 'legacy') {
      const { assetUrl, mimeType } = props.edition;
      return mapToLaunchMediaAsset({ assetUrl, mimeType }, { imageOptions });
    } else {
      const { media } = props.edition;
      return mapApiMediaToLaunchMedia(media, {
        imageOptions,
      });
    }
  };

  const stackMedia = getStackMedia();

  return (
    <FrameGridItem.Selectable {...props}>
      <EditionGridItemScaffolding
        dynamicLabel={
          <EditionGridItemDynamicLabel
            nftCount={edition.nftCount}
            sale={sale}
          />
        }
        enableLink={false}
        edition={props.edition}
        media={
          <Box
            css={{
              padding: '8%',
            }}
          >
            {stackMedia && (
              <MediaStack
                color={sale.status === 'OPEN' ? '$black100' : '$black5-solid'}
                stackCount={2}
                media={stackMedia}
              />
            )}
          </Box>
        }
        showBrandBadge={false}
        status={<EditionGridItemStatus mintPrice={sale.mintPrice} />}
      />
    </FrameGridItem.Selectable>
  );
}

function EditionGridItemSkeleton() {
  return <FrameGridItem.Skeleton />;
}

EditionGridItem.Selectable = EditionGridItemSelectable;
EditionGridItem.Skeleton = EditionGridItemSkeleton;
export default EditionGridItem;
