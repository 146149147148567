import { VirtualItem } from '@tanstack/react-virtual';
import { last } from 'ramda';
import { useEffect } from 'react';

interface FetchVirtualizedDataArgs<T> {
  data: T[];
  virtualItems: VirtualItem[];
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

export default function useFetchVirtualizedData<T>({
  data,
  virtualItems,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}: FetchVirtualizedDataArgs<T>) {
  // this useEffect fires as we scroll
  return useEffect(() => {
    // get the last element from the virtual scroll
    const lastItem = last(virtualItems);

    if (!lastItem) {
      return;
    }

    // when the last element is appended to the virtual scroll
    const isLastItem = lastItem.index === data.length - 1;

    // we then fire a request to get the next page
    if (isLastItem && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [data.length, virtualItems, isFetchingNextPage]);
}
