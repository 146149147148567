import { styled } from '@f8n-frontend/stitches';

import { DYNAMIC_LABEL_COPY } from 'copy/dynamic-label';

import { formatDateTime } from 'utils/dates/dates';

import { OnlyChildrenProps } from 'types/components';

import { Dot } from './Dot';
import Text from './base/Text';

function MintingSoon() {
  return (
    <FrameGridItemDynamicLabelText color="upcoming">
      {DYNAMIC_LABEL_COPY.mintingSoon}
    </FrameGridItemDynamicLabelText>
  );
}

function ScheduledText(props: { availableAtDate: Date }) {
  return (
    <FrameGridItemDynamicLabelText color="upcoming">
      {formatDateTime(props.availableAtDate)}
    </FrameGridItemDynamicLabelText>
  );
}

function AvailableText(props: OnlyChildrenProps) {
  return (
    <FrameGridItemDynamicLabelText color="available">
      {props.children}
    </FrameGridItemDynamicLabelText>
  );
}

function LiveText(props: OnlyChildrenProps) {
  return (
    <FrameGridItemDynamicLabelText color="live">
      {props.children}
    </FrameGridItemDynamicLabelText>
  );
}

function UnavailableText(props: OnlyChildrenProps) {
  return (
    <FrameGridItemDynamicLabelText color="unavailable">
      {props.children}
    </FrameGridItemDynamicLabelText>
  );
}

const FrameGridItemDynamicLabelGroup = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
});

const FrameGridItemDynamicLabelText = styled(Text, {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
});
FrameGridItemDynamicLabelText.defaultProps = {
  size: 1,
  lineHeight: 0,
};

const FrameGridItemDynamicLabelDot = styled(Dot);
FrameGridItemDynamicLabelDot.defaultProps = {
  size: 0,
};

export const FrameGridItemDynamicLabel = {
  Available: AvailableText,
  Dot: FrameGridItemDynamicLabelDot,
  Group: FrameGridItemDynamicLabelGroup,
  Live: LiveText,
  MintingSoon: MintingSoon,
  Scheduled: ScheduledText,
  Unavailable: UnavailableText,
};
