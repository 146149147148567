import useCountdown from 'hooks/use-countdown';
import { useIsHydrated } from 'hooks/use-is-hydrated';

import { CountdownV2 } from 'types/countdown';
import { CountdownMaxUnits } from 'types/duration';

type RenderCountdownProps = {
  endsAtTimestamp: number;
  maxUnits?: CountdownMaxUnits;
  render: (countdown: CountdownV2) => React.ReactNode | null;
};

export default function RenderCountdown(props: RenderCountdownProps) {
  const { maxUnits } = props;

  const countdown = useCountdown(props.endsAtTimestamp, { maxUnits });
  const isHydrated = useIsHydrated();

  // Avoid rendering countdown on the server to avoid client/server mismatch issues
  if (!isHydrated) {
    return null;
  }

  return props.render(countdown);
}
