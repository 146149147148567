import { useCallback, useEffect, useRef, useState } from 'react';

import { ModalKey, ModalOfType } from 'types/modal';

import useModal from './use-modal';

type ModalVisibility<Key extends ModalKey> = {
  config: ModalOfType<Key> | null;
  /** Is the Modal open? Should be passed to `Modal.Root` */
  open: boolean;
  /** Should we start hiding the Modal? */
  onOpenChange(): void;
  /** Should we reset the Modal store? Do this after the Modal has been unmounted. */
  onUnmount(): void;
};

/**
 * This hook is used to manage the relationship between our modal store, and the props passed to `Modal.Root`.
 *
 * At face value, it appears Modals have two possible states visibility states — Open and closed (via an `open` boolean).
 *
 * However, in reality there are three:
 *
 * 1. Open — Modal is animating in, or is visible.
 * 2. Closing — Modal is visible, but is animating out.
 * 3. Unmounted — Modal has finished animating out, and is then remove from the DOM.
 *
 * These three states are important, because without understanding them, it's very easy to break the exit animation or cause errors.
 *
 * This hook creates an abstraction on top of the modal store, to make it easier to integrate with our UI Modal components.
 *
 * @example
 * const modal = useModalVisibility(MODAL_KEY);
 *
 * return (
 *   <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
 *     <Modal.UnmountListener onUnmount={modal.onUnmount} />
 *     <SomeModalWindow {...modal.config} />
 *   </Modal.Root>
 * )
 */
const useModalVisibility = <Key extends ModalKey>(
  key: Key
): ModalVisibility<Key> => {
  const { activeModal, setModal } = useModal();

  const [open, setOpen] = useState(false);
  const configRef = useRef<ModalOfType<Key> | null>(null);
  const config = open ? (activeModal as ModalOfType<Key>) : configRef.current;

  useEffect(() => {
    if (activeModal && activeModal.type === key) {
      configRef.current = activeModal as ModalOfType<Key>;
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeModal]);

  const onOpenChange = useCallback(() => {
    setOpen(false);
  }, []);

  const onUnmount = useCallback(() => {
    setModal(null);
    configRef.current = null;
  }, []);

  return {
    config: open ? config : configRef.current,
    open,
    onOpenChange,
    onUnmount,
  };
};

export default useModalVisibility;
