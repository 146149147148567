import { GizmoIcon } from '@f8n/icons';

import EthText from 'components/base/EthText';
import Flex from 'components/base/Flex';

import { formatETHRounded, formatMintPrice } from 'utils/formatters';

import { Dynamic } from 'types/Drop';

interface MintPriceProps {
  mintPrice: number | Dynamic | null;
  size?: 'small' | 'large';
}

/** @deprecated - replace with component that can show min+max prices for dutch auction sale types */
export default function MintPrice(props: MintPriceProps) {
  const { mintPrice, size = 'small' } = props;
  if (mintPrice === 'dynamic') {
    return (
      <Flex css={{ alignItems: 'center', gap: '$1' }}>
        <GizmoIcon size={size === 'small' ? 1 : 3} /> Dynamic
      </Flex>
    );
  }

  if (mintPrice === null) return null;

  if (mintPrice === 0) return <>Free</>;

  return size === 'small' ? (
    <>{formatMintPrice(mintPrice)}</>
  ) : (
    <>
      {formatETHRounded(mintPrice)} <EthText>ETH</EthText>
    </>
  );
}
