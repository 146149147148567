import { parseJSON } from 'date-fns';

import { DYNAMIC_LABEL_COPY } from 'copy/dynamic-label';

import { dateTimeToUnix } from 'utils/dates/dates';
import { abbreviateValue, formatMintRatio } from 'utils/formatters';

import { EditionCollectionSale } from 'types/EditionSale';

import { FrameGridItemDynamicLabel } from './FrameGridItemDynamicLabel';
import RenderCountdown from './RenderCountdown';

export function EditionGridItemDynamicLabel(props: {
  nftCount: number;
  sale: EditionCollectionSale;
}) {
  const { nftCount, sale } = props;

  if (sale.type === 'TIMED_EDITION' && sale.status === 'OPEN') {
    return (
      <RenderCountdown
        endsAtTimestamp={dateTimeToUnix(sale.endTime)}
        render={(countdown) => {
          if (!countdown.message) {
            return (
              <FrameGridItemDynamicLabel.Unavailable>
                {abbreviateValue(nftCount)} minted
              </FrameGridItemDynamicLabel.Unavailable>
            );
          }

          return (
            <FrameGridItemDynamicLabel.Live>
              <span>{abbreviateValue(nftCount)} minted</span>
              <FrameGridItemDynamicLabel.Dot />
              <span>{DYNAMIC_LABEL_COPY.endsIn(countdown.message)}</span>
            </FrameGridItemDynamicLabel.Live>
          );
        }}
      />
    );
  }

  if (sale.status === 'OPEN' && nftCount === 0) {
    return (
      <FrameGridItemDynamicLabel.Available>
        {DYNAMIC_LABEL_COPY.mintingOpen}
      </FrameGridItemDynamicLabel.Available>
    );
  }

  if (sale.type === 'LIMITED_EDITION') {
    const mintRatio = formatMintRatio({
      minted: nftCount,
      supply: sale.maxTokenId,
    });

    if (nftCount === sale.maxTokenId) {
      return (
        <FrameGridItemDynamicLabel.Unavailable>
          {mintRatio}
        </FrameGridItemDynamicLabel.Unavailable>
      );
    }

    if (sale.status === 'OPEN') {
      return (
        <FrameGridItemDynamicLabel.Live>
          {mintRatio}
        </FrameGridItemDynamicLabel.Live>
      );
    }
  }

  if (sale.status === 'SCHEDULED') {
    return (
      <FrameGridItemDynamicLabel.Scheduled
        availableAtDate={parseJSON(sale.startTime)}
      />
    );
  }

  if (nftCount === 0) {
    return (
      <FrameGridItemDynamicLabel.Unavailable>
        {DYNAMIC_LABEL_COPY.ended}
      </FrameGridItemDynamicLabel.Unavailable>
    );
  }

  return (
    <FrameGridItemDynamicLabel.Unavailable>
      {abbreviateValue(nftCount)} minted
    </FrameGridItemDynamicLabel.Unavailable>
  );
}
