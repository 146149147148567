import { isFuture } from 'date-fns';

import { DYNAMIC_LABEL_COPY } from 'copy/dynamic-label';

import { getSaleStartDate } from 'utils/drop-sale';
import { formatMintRatio } from 'utils/formatters';

import { Drop } from 'types/Drop';

import { FrameGridItemDynamicLabel } from './FrameGridItemDynamicLabel';

type DropGridItemDynamicLabelProps = {
  nftCount: Drop['nftCount'];
  sale: Drop['sale'];
};

export function DropGridItemDynamicLabel(props: DropGridItemDynamicLabelProps) {
  const { nftCount, sale } = props;

  if (!sale) {
    return <FrameGridItemDynamicLabel.MintingSoon />;
  }

  const formattedMintRatio = formatMintRatio({
    minted: nftCount,
    supply: sale.maxTokenId,
  });

  if (sale.status === 'ENDED') {
    return (
      <FrameGridItemDynamicLabel.Unavailable>
        {formattedMintRatio}
      </FrameGridItemDynamicLabel.Unavailable>
    );
  }

  const startDate = getSaleStartDate(sale);

  if (sale.status === 'SCHEDULED' && isFuture(startDate)) {
    return <FrameGridItemDynamicLabel.Scheduled availableAtDate={startDate} />;
  }

  if (nftCount === sale.maxTokenId) {
    return (
      <FrameGridItemDynamicLabel.Unavailable>
        {formattedMintRatio}
      </FrameGridItemDynamicLabel.Unavailable>
    );
  }

  if (nftCount === 0) {
    return (
      <FrameGridItemDynamicLabel.Available>
        {DYNAMIC_LABEL_COPY.mintingOpen}
      </FrameGridItemDynamicLabel.Available>
    );
  }

  return (
    <FrameGridItemDynamicLabel.Live>
      {formattedMintRatio}
    </FrameGridItemDynamicLabel.Live>
  );
}
