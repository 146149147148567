import FrameGridItem, {
  FrameGridItemSelectableConfig,
} from 'components/FrameGridItem';
import FrameGridItemDetails, {
  FrameGridPresentedByWorld,
} from 'components/FrameGridItemDetails';
import Box from 'components/base/Box';

import { optimizeAsset } from 'utils/imgix';
import { getPath } from 'utils/router';

import { MarketAvailability } from 'types/MarketAvailability';
import { MarketNft, NftFilter } from 'types/Nft';
import { PreviewMediaAsset } from 'types/media';

import { NftGridItemDynamicLabel } from './NftGridItemDynamicLabel';
import NftGridItemStatus, { NftMarketFields } from './NftGridItemStatus';
import NftGridItemUnsupportedMedia from './NftGridItemUnsupportedMedia';
import NftMedia from './NftMedia';

type GridItemScaffoldingNft = NftFilter & Pick<MarketNft, 'creator' | 'name'>;

type NftGridItemScaffoldingProps = {
  dynamicLabel: React.ReactNode;
  nft: GridItemScaffoldingNft;
  media: PreviewMediaAsset | null;
  status: React.ReactNode;
  enableLink?: boolean;
  world?: FrameGridPresentedByWorld;
};

function NftGridItemScaffolding(props: NftGridItemScaffoldingProps) {
  const { enableLink = true, nft, media, status, world } = props;
  const { creator, name } = nft;

  return (
    <FrameGridItem
      href={enableLink ? getPath.token.page(nft) : undefined}
      media={
        media ? (
          <Box css={{ padding: '8%' }}>
            <NftMedia media={media} />
          </Box>
        ) : (
          <NftGridItemUnsupportedMedia />
        )
      }
      details={
        <FrameGridItemDetails
          dynamicLabel={props.dynamicLabel}
          name={name || ''}
          user={creator}
          status={status}
          tag={null}
          world={world}
        />
      }
    />
  );
}

export type GridItemMarketNft = GridItemScaffoldingNft &
  Pick<
    MarketNft,
    'activeAuction' | 'activeBuyNow' | 'activeOffer' | 'saleStartsAt'
  >;

type NftGridItemBaseProps = {
  world?: FrameGridPresentedByWorld;
  nft: GridItemMarketNft;
  media: PreviewMediaAsset | null;
  filterContext?: MarketAvailability;
};

function NftGridItemBase(props: NftGridItemBaseProps) {
  const { nft, media, filterContext, world } = props;

  return (
    <NftGridItemScaffolding
      dynamicLabel={<NftGridItemDynamicLabel nft={nft} />}
      nft={nft}
      media={media}
      status={<NftGridItemStatus nft={nft} filterContext={filterContext} />}
      world={world}
    />
  );
}

/**
 * Note: eventually we'll want to remove this type override and use GridItemMarketNft directly.
 * This is added temporarily because we don't currently render any status row for the selectable items
 */
type NftGridItemSelectableNftProps = Omit<
  GridItemMarketNft,
  'activeAuction'
> & {
  activeAuction: NftMarketFields['activeAuction'] | null;
};

type NftGridItemSelectableProps = FrameGridItemSelectableConfig & {
  nft: NftGridItemSelectableNftProps;
  media: PreviewMediaAsset | null;
};

function NftGridItemSelectable(props: NftGridItemSelectableProps) {
  const { nft, media } = props;

  return (
    <FrameGridItem.Selectable {...props}>
      <NftGridItemScaffolding
        dynamicLabel={<NftGridItemDynamicLabel nft={nft} />}
        nft={nft}
        media={media}
        status={<NftGridItemStatus nft={nft} filterContext={null} />}
        enableLink={false}
      />
    </FrameGridItem.Selectable>
  );
}

type Sale = {
  amount: number;
};

export type GridItemSoldNft = GridItemScaffoldingNft & { sale: Sale };

type NftGridItemSoldProps = {
  nft: GridItemSoldNft;
  media: PreviewMediaAsset | null;
  enableLink?: boolean;
};

function NftGridItemSold(props: NftGridItemSoldProps) {
  const { enableLink = true, media, nft } = props;

  return (
    <NftGridItemScaffolding
      dynamicLabel={<NftGridItemDynamicLabel.Sold />}
      enableLink={enableLink}
      nft={nft}
      media={media}
      status={<NftGridItemStatus.Sold saleAmount={nft.sale.amount} />}
    />
  );
}

function NftGridItemSkeleton() {
  return <FrameGridItem.Skeleton />;
}

type OptimizeOptions = {
  maxColumns: 2 | 3 | 4 | 5;
};

function optimizeNftGridItemMedia(
  media: PreviewMediaAsset,
  options: Partial<OptimizeOptions> = {}
): PreviewMediaAsset {
  const { maxColumns = 3 } = options;

  const width = maxColumnsToAssetWidthMap[maxColumns];

  if (media.type === 'video')
    return {
      ...media,
      src: optimizeAsset(media.src, {
        w: width,
        fm: 'mp4',
      }),
      poster: optimizeAsset(media.poster, {
        w: width,
        fm: 'jpg',
      }),
    };

  if (media.type === 'vector') {
    return media;
  }

  return {
    ...media,
    src: optimizeAsset(media.src, {
      w: width,
    }),
  };
}

const maxColumnsToAssetWidthMap: Record<OptimizeOptions['maxColumns'], number> =
  {
    '2': 800,
    '3': 650,
    '4': 500,
    '5': 350,
  };

const NftGridItem = Object.assign(NftGridItemBase, {
  Selectable: NftGridItemSelectable,
  Sold: NftGridItemSold,
  Skeleton: NftGridItemSkeleton,
  optimizeMedia: optimizeNftGridItemMedia,
});

export default NftGridItem;
