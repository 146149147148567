import { styled } from '@f8n-frontend/stitches';

import Box from 'components/base/Box';
import Skeleton from 'components/base/Skeleton';
import FollowButton from 'components/follows/FollowButton';

export default function VirtualisedUserListLoading() {
  const rows = 6;
  return (
    <Box css={{ paddingTop: '$2' }}>
      {Array.from({ length: rows }).map((_, index) => (
        <Box key={index} style={{ opacity: 1 - index / rows }}>
          <LoadingRow key={index} />
        </Box>
      ))}
    </Box>
  );
}

function LoadingRow() {
  return (
    <Container>
      <Skeleton.Avatar size={4} />
      <Inner>
        <Skeleton.Text css={{ height: 18, width: 120 }} />
        <Skeleton.Text css={{ height: 14, width: 80 }} />
      </Inner>
      <FollowButton.Skeleton css={{ marginLeft: 'auto' }} size={0} />
    </Container>
  );
}

const Container = styled('div', {
  gap: '$4',
  height: 72,
  paddingX: '$5',
  display: 'flex',
  alignItems: 'center',
});

const Inner = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});
