import { isBefore, parseJSON } from 'date-fns';
import { anyPass } from 'ramda';
import { match, P } from 'ts-pattern';

import { CollectionSale } from 'types/CollectionSale';
import {
  DropSale,
  FndLinearDutchAuctionSale,
  FndFixedPriceSale,
  HighlightStaggeredDutchAuctionSale,
  HighlightFixedPriceSale,
} from 'types/DropSale';

export const LIMIT_PER_ACCOUNT = 10;

export const isFndLinearDutchAuctionSale = (
  sale: CollectionSale
): sale is FndLinearDutchAuctionSale => {
  return sale.type === 'FND_LINEAR_DUTCH_AUCTION';
};

export const isFndFixedPriceSale = (
  sale: CollectionSale
): sale is FndFixedPriceSale => {
  return sale.type === 'FND_FIXED_PRICE';
};

export const isHighlightStaggeredDutchAuctionSale = (
  sale: CollectionSale
): sale is HighlightStaggeredDutchAuctionSale => {
  return sale.type === 'HIGHLIGHT_STAGGERED_DUTCH_AUCTION';
};

export const isHighlightFixedPriceSale = (
  sale: CollectionSale
): sale is HighlightFixedPriceSale => {
  return sale.type === 'HIGHLIGHT_FIXED_PRICE';
};

export const isHighlightSale = (
  sale: CollectionSale
): sale is HighlightFixedPriceSale | HighlightStaggeredDutchAuctionSale => {
  return anyPass([
    isHighlightFixedPriceSale,
    isHighlightStaggeredDutchAuctionSale,
  ])(sale);
};

export const getSaleStartDate = (sale: DropSale): Date => {
  return match(sale)
    .with(
      { type: 'FND_FIXED_PRICE', presaleStartTime: P.string },
      ({ startTime, presaleStartTime }) => {
        const presaleStartDate = parseJSON(presaleStartTime);
        const startDate = parseJSON(startTime);

        return isBefore(presaleStartDate, startDate)
          ? presaleStartDate
          : startDate;
      }
    )
    .otherwise((data) => parseJSON(data.startTime));
};
