import FrameGridItem, {
  FrameGridItemSelectableConfig,
} from 'components/FrameGridItem';
import FrameGridItemDetails, {
  FrameGridPresentedByWorld,
} from 'components/FrameGridItemDetails';

import { getMintPrice } from 'utils/collections';
import { mapApiMediaToLaunchMedia } from 'utils/media';
import { getPath } from 'utils/router';

import { CollectionFilter } from 'types/Collection';
import { Drop } from 'types/Drop';
import { LaunchMediaAsset, OptimizeImageOptions } from 'types/media';

import BrandBadge from './BrandBadge';
import { DropGridItemDynamicLabel } from './DropGridItemDynamicLabel';
import MediaStackHorizontal from './MediaStackHorizontal';
import Box from './base/Box';
import MintPrice from './collections/MintPrice';

type DropGridItemScaffoldingDrop = CollectionFilter &
  Pick<Drop, 'creator' | 'name'>;

type GridItemStatusDrop = {
  /** @deprecated migrate to using sale instead */
  clearingPrice: Drop['clearingPrice'];
  /** @deprecated migrate to using sale instead */
  mintPrice: Drop['mintPrice'];
  /** @deprecated migrate to using sale instead */
  saleType: Drop['saleType'];
};

export type GridItemDrop = DropGridItemScaffoldingDrop &
  GridItemStatusDrop &
  Pick<Drop, 'media' | 'nftCount'>;

type DropGridItemScaffoldingProps = {
  drop: DropGridItemScaffoldingDrop;
  dynamicLabel: React.ReactNode;
  enableLink?: boolean;
  media: React.ReactNode;
  status: React.ReactNode;
  showBrandBadge: boolean;
  world?: FrameGridPresentedByWorld;
};

function DropGridItemScaffolding(props: DropGridItemScaffoldingProps) {
  const {
    drop,
    dynamicLabel,
    enableLink = true,
    media,
    showBrandBadge,
    status,
    world,
  } = props;

  return (
    <FrameGridItem
      href={enableLink ? getPath.mint.page(drop) : undefined}
      media={media}
      details={
        <FrameGridItemDetails
          world={world}
          dynamicLabel={dynamicLabel}
          name={drop.name}
          user={drop.creator}
          status={status}
          tag={showBrandBadge ? <BrandBadge product="drop" /> : null}
        />
      }
    />
  );
}

function DropGridItem(props: {
  drop: GridItemDrop;
  sale: Drop['sale'];
  world?: FrameGridPresentedByWorld;
}) {
  const { drop, sale, world } = props;

  const getMedia = (): LaunchMediaAsset | null => {
    const imageOptions: OptimizeImageOptions = { q: 70, h: 640, w: 640 };

    return mapApiMediaToLaunchMedia(props.drop.media, {
      imageOptions,
    });
  };

  const media = getMedia();

  return (
    <DropGridItemScaffolding
      world={world}
      drop={drop}
      dynamicLabel={
        <DropGridItemDynamicLabel nftCount={drop.nftCount} sale={sale} />
      }
      media={
        media ? (
          <Box
            css={{
              // padding intentionally slightly more than the editions and NFT grid items to account for the shape of the media stack
              padding: '9%',
            }}
          >
            <MediaStackHorizontal count={3} media={media} />
          </Box>
        ) : null
      }
      status={<DropGridItemStatus drop={drop} sale={sale} />}
      showBrandBadge
    />
  );
}

function DropGridItemStatus(props: {
  drop: GridItemStatusDrop;
  sale: Drop['sale'];
}) {
  const { drop, sale } = props;
  const { clearingPrice, saleType, mintPrice } = drop;

  if (!sale) {
    return <FrameGridItemDetails.EmptyStatusRow />;
  }

  return (
    <FrameGridItemDetails.StatusRow>
      <FrameGridItemDetails.StatusText>
        <MintPrice
          mintPrice={getMintPrice({
            saleType,
            mintPrice,
            clearingPrice,
          })}
        />
      </FrameGridItemDetails.StatusText>
    </FrameGridItemDetails.StatusRow>
  );
}

type DropGridItemSelectableProps = FrameGridItemSelectableConfig & {
  drop: GridItemDrop;
  sale: Drop['sale'];
};

function DropGridItemSelectable(props: DropGridItemSelectableProps) {
  const { drop, sale } = props;

  const getStackMedia = (): LaunchMediaAsset | null => {
    const imageOptions: OptimizeImageOptions = { q: 70, h: 640, w: 640 };

    const { media } = props.drop;
    return mapApiMediaToLaunchMedia(media, {
      imageOptions,
    });
  };

  const stackMedia = getStackMedia();

  return (
    <FrameGridItem.Selectable {...props}>
      <DropGridItemScaffolding
        drop={props.drop}
        dynamicLabel={
          <DropGridItemDynamicLabel nftCount={drop.nftCount} sale={sale} />
        }
        enableLink={false}
        media={
          <Box
            css={{
              padding: '8%',
            }}
          >
            {stackMedia && (
              <MediaStackHorizontal count={3} media={stackMedia} />
            )}
          </Box>
        }
        status={<DropGridItemStatus drop={drop} sale={sale} />}
        showBrandBadge={false}
      />
    </FrameGridItem.Selectable>
  );
}

function DropGridItemSkeleton() {
  return <FrameGridItem.Skeleton />;
}

DropGridItem.Selectable = DropGridItemSelectable;
DropGridItem.Skeleton = DropGridItemSkeleton;
export default DropGridItem;
