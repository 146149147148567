import { CalendarAddIcon, CheckIcon, PlusIcon } from '@f8n/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useHoverDirty } from 'react-use';
import { match } from 'ts-pattern';

import useAnalytics from 'contexts/analytics/useAnalytics';
import { hasPublicKey, hasToken, isPending } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import { BOOKMARK_COPY } from 'copy/bookmark';
import useNotifications from 'state/stores/notifications';

import { ApiMomentFragment } from 'gql/api/api-fragments.generated';
import { useCreateBookmark } from 'gql/api/mutations/create-bookmark.generated';
import { useDeleteBookmark } from 'gql/api/mutations/delete-bookmark.generated';
import { useIsHydrated } from 'hooks/use-is-hydrated';
import useModal from 'hooks/use-modal';
import report from 'lib/report';
import { getPath } from 'utils/router';

import Avatar from './base/Avatar';
import Button from './base/Button';

type MomentCalendarButtonProps = {
  bookmarkId: string | null;
  moment: ApiMomentFragment;
  variant: 'subtle' | 'primary';
};

const BUTTON_SIZE = 0;

export function MomentCalendarButton(props: MomentCalendarButtonProps) {
  const { bookmarkId, moment, variant } = props;

  const momentId = moment.id;

  const auth = useAuth();
  const modal = useModal();

  const router = useRouter();

  const [isBookmarked, setIsBookmarked] = useState(Boolean(bookmarkId));

  const isHydrated = useIsHydrated();

  const queryClient = useQueryClient();

  const notification = useNotifications();

  const analytics = useAnalytics();

  useEffect(() => {
    setIsBookmarked(Boolean(bookmarkId));
  }, [bookmarkId]);

  const handleNotification = (options: {
    message: string;
    variant: 'error' | 'success';
  }) => {
    const { message, variant } = options;

    match(variant)
      .with('error', () => {
        return notification.show.error({
          message,
        });
      })
      .with('success', () => {
        return notification.show.info({
          message,
          icon: isHydrated ? (
            <Avatar shape="square" size={3} imageUrl={moment.posterUrl} />
          ) : null,
          action: {
            cta: 'View cal',
            altText: 'View cal',
            onAction: () => {
              router.push(getPath.exhibitions.calendar);
            },
          },
        });
      })
      .exhaustive();
  };

  const createBookmark = useCreateBookmark({
    onError: (error) => {
      report(error);
      handleNotification({
        message: 'Failed to update calendar',
        variant: 'error',
      });
    },
    onSuccess: () => {
      setIsBookmarked(true);
      handleNotification({ message: 'Saved to calendar', variant: 'success' });
      queryClient.invalidateQueries();
      analytics.track({
        name: 'saved_to_calendar',
        momentId,
        worldId: moment.worldId,
      });
    },
  });

  const deleteBookmark = useDeleteBookmark({
    onError: (error) => {
      report(error);
      handleNotification({
        message: 'Failed to update calendar',
        variant: 'error',
      });
    },
    onSuccess: () => {
      setIsBookmarked(false);
      handleNotification({
        message: 'Removed from calendar',
        variant: 'success',
      });
      queryClient.invalidateQueries();
      analytics.track({
        name: 'removed_from_calendar',
        momentId,
        worldId: moment.worldId,
      });
    },
  });

  if (!isHydrated || isPending(auth)) {
    return (
      <Button size={BUTTON_SIZE} variant="subtle" disabled icon>
        <CalendarAddIcon />
        <span>{BOOKMARK_COPY.SAVE}</span>
      </Button>
    );
  }

  if (isBookmarked) {
    return (
      <HoverableRemoveButton
        onRemove={() => {
          if (!bookmarkId) return;
          deleteBookmark.mutate({ bookmarkId });
        }}
      />
    );
  }

  if (createBookmark.isPending) {
    return (
      <Button size={BUTTON_SIZE} variant="subtle" disabled icon>
        <PlusIcon />
        <span>{BOOKMARK_COPY.SAVING}</span>
      </Button>
    );
  }

  if (!hasPublicKey(auth)) {
    return (
      <Button
        size={BUTTON_SIZE}
        variant={variant}
        onClick={() => {
          auth.startLogin();
        }}
        icon
      >
        <CalendarAddIcon />
        <span>{BOOKMARK_COPY.SAVE}</span>
      </Button>
    );
  }

  if (!hasToken(auth)) {
    return (
      <Button
        size={BUTTON_SIZE}
        variant={variant}
        onClick={() => {
          modal.setModal({ type: 'AUTH_PRE_SIGN' });
        }}
        icon
      >
        <CalendarAddIcon />
        <span>{BOOKMARK_COPY.SAVE}</span>
      </Button>
    );
  }

  return (
    <Button
      size={BUTTON_SIZE}
      variant={variant}
      onClick={() => {
        createBookmark.mutate({
          momentId,
        });
      }}
      icon
    >
      <CalendarAddIcon />
      <span>{BOOKMARK_COPY.SAVE}</span>
    </Button>
  );
}

function HoverableRemoveButton(props: { onRemove: () => void }) {
  const ref = useRef(null);
  const isHovering = useHoverDirty(ref);

  if (isHovering) {
    return (
      <Button
        size={BUTTON_SIZE}
        variant="subtle"
        icon
        ref={ref}
        onClick={props.onRemove}
        style={{ width: SIZE_TO_FIXED_WIDTH_MAP[0] }}
      >
        <span>{BOOKMARK_COPY.REMOVE_SAVE}</span>
      </Button>
    );
  }

  return (
    <Button
      size={BUTTON_SIZE}
      variant="subtle"
      icon
      ref={ref}
      style={{ width: SIZE_TO_FIXED_WIDTH_MAP[0] }}
    >
      <CheckIcon />
      <span>{BOOKMARK_COPY.SAVED}</span>
    </Button>
  );
}

const SIZE_TO_FIXED_WIDTH_MAP = {
  0: 93,
} as const;
