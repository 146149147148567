import { parseJSON } from 'date-fns';
import React from 'react';

import { DYNAMIC_LABEL_COPY } from 'copy/dynamic-label';

import { dateTimeToUnix, isUpcoming } from 'utils/dates/dates';

import { ActiveAuction, MarketNft } from 'types/Nft';

import { FrameGridItemDynamicLabel } from './FrameGridItemDynamicLabel';
import RenderCountdown from './RenderCountdown';

export type NftGridItemDynamicLabelProps = {
  nft: {
    activeAuction: Pick<
      ActiveAuction,
      'expiryDate' | 'highestBid' | 'state' | 'reservePrice'
    > | null;
    activeBuyNow: MarketNft['activeBuyNow'];
    saleStartsAt: MarketNft['saleStartsAt'];
  };
};

// TODO (dynamic-label): add support for offers

export function NftGridItemDynamicLabel(props: NftGridItemDynamicLabelProps) {
  const { nft } = props;

  if (isUpcoming(nft.saleStartsAt)) {
    return (
      <FrameGridItemDynamicLabel.Scheduled
        availableAtDate={parseJSON(nft.saleStartsAt)}
      />
    );
  }

  if (nft.activeAuction) {
    const auction = nft.activeAuction;

    switch (auction.state) {
      case 'OPEN': {
        return (
          <FrameGridItemDynamicLabel.Available>
            {DYNAMIC_LABEL_COPY.available}
          </FrameGridItemDynamicLabel.Available>
        );
      }
      case 'LIVE': {
        if (!auction.highestBid) break;
        if (!auction.expiryDate) break;
        /**
         * TODO: replace with `x bids` copy
         * @see https://linear.app/fnd/issue/FND-9446/update-api-to-expose-totalbids-count-for-auctions-on-marketnft-type
         */
        const bidsCount = 'Live auction';

        return (
          <RenderCountdown
            endsAtTimestamp={dateTimeToUnix(auction.expiryDate)}
            render={(countdown) => {
              if (!countdown.message)
                return (
                  <FrameGridItemDynamicLabel.Unavailable>
                    {DYNAMIC_LABEL_COPY.sold}
                  </FrameGridItemDynamicLabel.Unavailable>
                );

              return (
                <FrameGridItemDynamicLabel.Live>
                  <span>{bidsCount}</span>
                  <FrameGridItemDynamicLabel.Dot />
                  <span>{DYNAMIC_LABEL_COPY.endsIn(countdown.message)}</span>
                </FrameGridItemDynamicLabel.Live>
              );
            }}
          />
        );
      }
      case 'ENDED':
      case 'FINALIZED': {
        if (!auction.highestBid) break;
        return (
          <FrameGridItemDynamicLabel.Unavailable>
            {DYNAMIC_LABEL_COPY.sold}
          </FrameGridItemDynamicLabel.Unavailable>
        );
      }
    }
  }

  if (nft.activeBuyNow) {
    return (
      <FrameGridItemDynamicLabel.Available>
        {DYNAMIC_LABEL_COPY.available}
      </FrameGridItemDynamicLabel.Available>
    );
  }

  return null;
}

NftGridItemDynamicLabel.Sold = function NftGridItemDynamicLabelSold() {
  return (
    <FrameGridItemDynamicLabel.Unavailable>
      {DYNAMIC_LABEL_COPY.sold}
    </FrameGridItemDynamicLabel.Unavailable>
  );
};
