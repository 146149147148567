import React from 'react';

import { formatETHWithSuffix, formatNftPrice } from 'utils/formatters';

import { MarketAvailability } from 'types/MarketAvailability';
import { ActiveAuction, MarketNft } from 'types/Nft';

import FrameGridItemDetails from './FrameGridItemDetails';

export type NftMarketFields = {
  activeAuction: Pick<
    ActiveAuction,
    'expiryDate' | 'highestBid' | 'state' | 'reservePrice'
  > | null;
  activeBuyNow: MarketNft['activeBuyNow'];
};

type NftGridItemStatusProps = {
  filterContext?: MarketAvailability | null;
  nft: NftMarketFields;
};

function NftGridItemStatus(props: NftGridItemStatusProps) {
  const { filterContext, nft } = props;

  if (filterContext === 'HAS_ACTIVE_BUY_NOW' && nft.activeBuyNow) {
    return <PriceStatusRow price={nft.activeBuyNow.amount} />;
  }

  if (filterContext === 'RESERVE_NOT_MET' && nft.activeAuction) {
    return <PriceStatusRow price={nft.activeAuction.reservePrice} />;
  }

  if (nft.activeAuction) {
    const auction = nft.activeAuction;

    switch (auction.state) {
      case 'LIVE': {
        if (!auction.highestBid) break;
        if (!auction.expiryDate) break;
        return <PriceStatusRow price={auction.highestBid.amount} />;
      }
      case 'OPEN': {
        return (
          <PriceStatusRow
            price={
              nft.activeBuyNow
                ? Math.min(nft.activeBuyNow.amount, auction.reservePrice)
                : auction.reservePrice
            }
          />
        );
      }
      case 'ENDED':
      case 'FINALIZED': {
        if (!auction.highestBid) break;
        return <SoldStatusRow saleAmount={auction.highestBid.amount} />;
      }
    }
  }

  if (nft.activeBuyNow) {
    return <PriceStatusRow price={nft.activeBuyNow.amount} />;
  }

  return null;
}

function PriceStatusRow(props: { price: number }) {
  return (
    <FrameGridItemDetails.StatusRow>
      <FrameGridItemDetails.StatusText>
        {formatNftPrice(props.price)}
      </FrameGridItemDetails.StatusText>
    </FrameGridItemDetails.StatusRow>
  );
}

function SoldStatusRow(props: { saleAmount: number }) {
  return (
    <FrameGridItemDetails.StatusRow>
      <FrameGridItemDetails.StatusText>
        {formatETHWithSuffix(props.saleAmount)}
      </FrameGridItemDetails.StatusText>
    </FrameGridItemDetails.StatusRow>
  );
}

NftGridItemStatus.Sold = SoldStatusRow;
export default NftGridItemStatus;
