import { styled } from '@f8n-frontend/stitches';
import { useQueryClient } from '@tanstack/react-query';

import Box from 'components/base/Box';
import Heading from 'components/base/Heading';
import Modal from 'components/base/Modal';
import Tabs from 'components/base/Tabs';
import VirtualisedUserListEmptyState from 'components/modals/virtualized/VirtualisedUserListEmptyState';
import VirtualisedUserListLoading from 'components/modals/virtualized/VirtualisedUserListLoading';
import VirtualizedUserList from 'components/modals/virtualized/VirtualizedUserList';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import { MOMENT_CREATORS_COPY } from 'copy/moments';

import { useInfiniteMomentCreators } from 'gql/api/queries/moment-creators.generated';
import useModalVisibility from 'hooks/use-modal-visibility';
import { formatNumber } from 'utils/formatters';
import { apiPaginator, extractNestedPaginatedData } from 'utils/react-query';

type MomentCreatorsModalProps = {
  creatorsCount: number;
};

const Wrapper = styled(Box, {
  marginX: '$3',
  marginBottom: '$3',
  paddingTop: '$4',
  paddingBottom: '$5',
  borderBottom: '1px solid $black5',
});

const MODAL_KEY = 'MOMENT_CREATORS';

export default function MomentCreatorsModal(props: MomentCreatorsModalProps) {
  const { creatorsCount } = props;

  const auth = useAuth();

  const modal = useModalVisibility(MODAL_KEY);

  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : '';

  return (
    <Modal.Root open={modal.open} onOpenChange={modal.onOpenChange}>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Modal.UnmountListener onUnmount={modal.onUnmount} />

          {modal.config && (
            <Tabs.Root defaultValue="creators">
              <Modal.Window
                size={0}
                header={
                  <Modal.Header>
                    <Tabs.List>
                      <Tabs.Trigger value="creators">
                        Creators{' '}
                        <Tabs.Count>{formatNumber(creatorsCount)}</Tabs.Count>
                      </Tabs.Trigger>
                    </Tabs.List>
                  </Modal.Header>
                }
                height="normal"
              >
                <Tabs.Content value="creators">
                  <CreatorsTab
                    momentId={modal.config.momentId}
                    currentUserPublicKey={currentUserPublicKey}
                  />
                </Tabs.Content>
              </Modal.Window>
            </Tabs.Root>
          )}
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

type UserTabProps = {
  momentId: number;
  currentUserPublicKey: string;
};

function CreatorsTab(props: UserTabProps) {
  const { momentId } = props;

  const infiniteMomentCreators = useInfiniteMomentCreators(
    { momentId },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        return apiPaginator.getNextPageParam(lastPage.momentCreators);
      },
      initialPageParam: apiPaginator.initialPageParam,
    }
  );

  const queryClient = useQueryClient();

  if (infiniteMomentCreators.isLoading || !infiniteMomentCreators.data) {
    return <VirtualisedUserListLoading />;
  }

  const creators = extractNestedPaginatedData(
    infiniteMomentCreators.data,
    'momentCreators'
  );

  if (creators.items.length === 0) {
    return <NoCreators />;
  }

  return (
    <VirtualizedUserList
      title={
        <Wrapper>
          <Heading>{MOMENT_CREATORS_COPY.heading}</Heading>
        </Wrapper>
      }
      users={creators.items}
      onFollowUpdate={() => {
        queryClient.invalidateQueries();
      }}
      handleNextPage={() => infiniteMomentCreators.fetchNextPage()}
      isFetchingNextPage={infiniteMomentCreators.isFetchingNextPage}
      hasNextPage={infiniteMomentCreators.hasNextPage || false}
    />
  );
}

function NoCreators() {
  return (
    <VirtualisedUserListEmptyState
      heading={MOMENT_CREATORS_COPY.empty.heading}
      subheading={MOMENT_CREATORS_COPY.empty.subheading}
    />
  );
}
