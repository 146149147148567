import { BRAND_ICONS } from 'lib/icons';

import { IconProps } from 'types/icon';
import { ProductCategory } from 'types/product-category';

type BrandIconProps = Pick<IconProps, 'size'> & {
  product: ProductCategory;
};

/** Icon to show with the brand name */
export default function BrandIcon(props: BrandIconProps) {
  const Icon = BRAND_ICONS[props.product];

  return <Icon size={props.size} />;
}
