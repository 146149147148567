import Pulse from 'components/Pulse';
import WorldStat from 'components/WorldStat';
import Dash from 'components/base/Dash';
import Flex from 'components/base/Flex';

import { formatTotalEthValue } from 'utils/formatters';

import { MomentStatsType } from 'types/Moment';

type MomentStatsProps = {
  onWorksClick?(): void;
  stats: MomentStatsType;
};

export function MomentStats(props: MomentStatsProps) {
  const { onWorksClick, stats } = props;

  return (
    <WorldStat.Root>
      <WorksStat
        totalWorks={stats.worksCount}
        hasLiveSales={stats.liveActivityCount > 0}
        onWorksClick={onWorksClick}
      />
      <CollectorsStat totalCollectors={stats.collectorsCount} />
      <SalesStat totalSales={stats.totalSales} />
    </WorldStat.Root>
  );
}

MomentStats.Skeleton = function MomentStatsSkeleton() {
  return (
    <WorldStat.Root>
      <WorldStat.Wrapper>
        <WorldStat.Label>Works</WorldStat.Label>
        <WorldStat.Value>
          <Dash />
        </WorldStat.Value>
      </WorldStat.Wrapper>
      <WorldStat.Wrapper>
        <WorldStat.Label>Collectors</WorldStat.Label>
        <WorldStat.Value>
          <Dash />
        </WorldStat.Value>
      </WorldStat.Wrapper>
      <WorldStat.Wrapper>
        <WorldStat.Label>Sales</WorldStat.Label>
        <WorldStat.Value>
          <Dash />
        </WorldStat.Value>
      </WorldStat.Wrapper>
    </WorldStat.Root>
  );
};

function WorksStat(props: {
  totalWorks: number;
  hasLiveSales: boolean;
  onWorksClick?(): void;
}) {
  const { totalWorks, hasLiveSales, onWorksClick } = props;

  const getWorksLabel = () => {
    if (hasLiveSales) {
      return (
        <Flex
          css={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: '$1',
          }}
        >
          <Pulse color="$purple4" />
          <WorldStat.Value>{totalWorks}</WorldStat.Value>
        </Flex>
      );
    } else {
      return <WorldStat.Value>{totalWorks}</WorldStat.Value>;
    }
  };

  return (
    <WorldStat.Wrapper clickable={totalWorks > 0 && Boolean(onWorksClick)}>
      <WorldStat.ClickableBackground onClick={onWorksClick} />
      <WorldStat.Label>Works</WorldStat.Label>
      {getWorksLabel()}
    </WorldStat.Wrapper>
  );
}

function CollectorsStat(props: { totalCollectors: number }) {
  const { totalCollectors } = props;

  return (
    <WorldStat.Wrapper clickable={false}>
      <WorldStat.Label>Collectors</WorldStat.Label>
      <WorldStat.Value>{totalCollectors}</WorldStat.Value>
    </WorldStat.Wrapper>
  );
}

function SalesStat(props: { totalSales: number }) {
  const { totalSales } = props;

  const hasSales = totalSales > 0;

  return (
    <WorldStat.Wrapper clickable={false}>
      <WorldStat.Label>Sales</WorldStat.Label>
      <WorldStat.Value css={{ whiteSpace: 'nowrap' }}>
        {hasSales ? `${formatTotalEthValue(totalSales)} ETH` : <Dash />}
      </WorldStat.Value>
    </WorldStat.Wrapper>
  );
}
