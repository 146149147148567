import { styled } from '@f8n-frontend/stitches';

import { PRODUCT_BRANDING } from 'copy/branding';

import { ProductCategory } from 'types/product-category';

import BrandIcon from './BrandIcon';

type BrandBadgeProps = {
  product: ProductCategory;
};

/** Shows the name and icon branding for a product */
export default function BrandBadge(props: BrandBadgeProps) {
  return (
    <Root>
      <BrandIcon product={props.product} />
      {PRODUCT_BRANDING[props.product].singular}
    </Root>
  );
}

// TODO (1155): Move styles to `Badge` component once design updates on their end
const Root = styled('div', {
  lineHeight: '$1',
  letterSpacing: '$0',
  whiteSpace: 'nowrap',
  fontWeight: '$medium',

  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '$round',
  border: '1px solid transparent',

  gap: '$1',
  fontSize: '$0',
  lineheight: 0,
  paddingX: '$2',
  paddingY: '$1',

  color: '$black70',

  boxShadow: 'inset 0px 0px 0px 1px $colors$black10',

  svg: {
    width: '12px',
    height: '12px',
  },
});
