import { match } from 'ts-pattern';

import { ApiDropSaleConfigurationFragment } from 'gql/api/api-fragments.generated';

import { DropSale } from 'types/DropSale';

type DropSaleByType<Type extends DropSale['type']> = Extract<
  DropSale,
  { type: Type }
>;

/**
 * @param saleConfiguration a union of all possible sale configurations on the ApiDropFragment
 * @returns a normalized DropSale object or null if no sale configuration is present
 *
 * Note that due to limitations with GraphQL unions, some data passed in via the saleConfigurationUnion has aliased fields
 * like `optionalEndTime`. This function normalizes those aliased fields to the expected field names.
 */
export const mapDropSaleConfigurationToDropSale = (
  saleConfiguration: ApiDropSaleConfigurationFragment | null
): DropSale | null => {
  if (!saleConfiguration) return null;

  return match(saleConfiguration)
    .with(
      { __typename: 'LinearDutchAuctionSaleConfiguration' },
      (sale): DropSaleByType<'FND_LINEAR_DUTCH_AUCTION'> => {
        return {
          type: 'FND_LINEAR_DUTCH_AUCTION',
          ...sale,
          status: sale.fndLinearDutchAuctionStatus,
        };
      }
    )
    .with(
      { __typename: 'FixedPriceSaleConfiguration' },
      (sale): DropSaleByType<'FND_FIXED_PRICE'> => {
        return {
          type: 'FND_FIXED_PRICE',
          ...sale,
          status: sale.fndFixedPriceSaleStatus,
        };
      }
    )
    .with(
      { __typename: 'HighlightStaggeredDutchAuctionSaleConfiguration' },
      (sale): DropSaleByType<'HIGHLIGHT_STAGGERED_DUTCH_AUCTION'> => {
        return {
          type: 'HIGHLIGHT_STAGGERED_DUTCH_AUCTION',
          ...sale,
          status: sale.highlightStaggeredDutchAuctionStatus,
        };
      }
    )
    .with(
      { __typename: 'HighlightFixedPriceSaleConfiguration' },
      (sale): DropSaleByType<'HIGHLIGHT_FIXED_PRICE'> => {
        return {
          type: 'HIGHLIGHT_FIXED_PRICE',
          ...sale,
          mintVectorId: sale.vectorId,
          endTime: sale.optionalEndTime,
          maxTokenId: sale.optionalMaxTokenId,
          status: sale.highlightFixedPriceSaleStatus,
        };
      }
    )
    .otherwise(() => {
      throw new Error('Unknown drop sale configuration type');
    });
};
