import { styled } from '@f8n-frontend/stitches';

import Empty from 'components/Empty';

type VirtualisedUserListEmptyStateProps = {
  heading: string;
  subheading: string;
};

export default function VirtualisedUserListEmptyState(
  props: VirtualisedUserListEmptyStateProps
) {
  const { heading, subheading } = props;
  return (
    <Center>
      <Empty heading={heading} subheading={subheading} variant="modal" />
    </Center>
  );
}

const Center = styled('div', {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingX: '$4',

  '@bp0': {
    paddingX: '$7',
  },
});
