import { GridIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { Fragment, useState } from 'react';
import { useHarmonicIntervalFn } from 'react-use';

import { hasPublicKey, isAdmin } from 'contexts/auth/helpers';
import { Auth } from 'contexts/auth/types';

import { ApiMomentFragment } from 'gql/api/api-fragments.generated';
import { useMomentBookmarks } from 'gql/api/mutations/moment-bookmarks.generated';
import useWorldRole from 'hooks/queries/api/use-world-role';
import useModal from 'hooks/use-modal';
import { ImageDimensions } from 'queries/get-imgix-metaadata';
import {
  getUsernameOrAddress,
  getUsernameOrAddressInfo,
  noop,
} from 'utils/helpers';
import { optimizeAsset } from 'utils/imgix';
import { getMomentStatus } from 'utils/moment';
import { getPath, managePaths } from 'utils/router';
import { getConjuction } from 'utils/strings';

import { UserLight } from 'types/Account';
import { MomentStatsType } from 'types/Moment';
import { WorldOverview } from 'types/World';

import { MomentCalendarButton } from './MomentCalendarButton';
import MomentDynamicLabel from './MomentDynamicLabel';
import Button from './base/Button';
import Flex from './base/Flex';
import Heading from './base/Heading';
import Image from './base/Image';
import Link from './base/Link';
import Skeleton from './base/Skeleton';
import Text from './base/Text';
import MomentCreatorsModal from './modals/MomentCreatorsModal';
import { MomentStats } from './moments/MomentStats';
import ProfileHoverCard from './profiles/ProfileHoverCard';
import WorldTag from './worlds/WorldTag';

type MomentPageHeaderProps = {
  moment: ApiMomentFragment;
  momentStats: MomentStatsType;
  contributors: UserLight[];
  contributorsCount: number;
  world: WorldOverview;
  imageDimensions: ImageDimensions;
  alwaysHideDashboardButton?: boolean;
  auth: Auth;
};

export default function MomentPageHeader(props: MomentPageHeaderProps) {
  const {
    moment,
    momentStats,
    world,
    imageDimensions,
    contributors,
    contributorsCount,
    auth,
  } = props;

  const optimizedAsset = optimizeAsset(moment.posterUrl, {
    w: 800,
    auto: undefined,
    dpr: 2,
  });

  const [momentStatus, setMomentStatus] = useState(() =>
    getMomentStatus({
      startsAt: moment.startsAt,
      liveActivityCount: momentStats.liveActivityCount,
      isSoldOut: momentStats.isSoldOut,
    })
  );

  const worldRoleQuery = useWorldRole(
    { worldId: world.id, publicKey: hasPublicKey(auth) ? auth.publicKey : '' },
    { enabled: hasPublicKey(auth) }
  );

  const momentBookmarksQuery = useMomentBookmarks(
    {
      publicKey: hasPublicKey(auth) ? auth.publicKey : '',
      perPage: 100,
      page: 0,
    },
    { enabled: hasPublicKey(auth) }
  );

  const worldRole = worldRoleQuery.isSuccess ? worldRoleQuery.data : null;

  const showDashboardButton = worldRole !== null || isAdmin(auth);

  const editMomentHref = managePaths.moment({
    momentId: moment.id,
    worldId: world.id,
  }).dashboard;

  const scrollToWorks = () => {
    const worksEl = document.getElementById('works');

    if (!worksEl) return;

    worksEl.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useHarmonicIntervalFn(() => {
    setMomentStatus(
      getMomentStatus({
        startsAt: moment.startsAt,
        liveActivityCount: momentStats.liveActivityCount,
        isSoldOut: momentStats.isSoldOut,
      })
    );
  }, 1_000);

  const modal = useModal();

  const getBookmarkId = (): string | null => {
    if (momentBookmarksQuery.data) {
      const momentBookmark =
        momentBookmarksQuery.data.momentBookmarks.items.find(
          (item) => item.content.id === moment.id
        );

      return momentBookmark ? momentBookmark.id : null;
    } else {
      return null;
    }
  };

  const getCallToAction = () => {
    if (momentStatus === 'UPCOMING') {
      return (
        <Flex css={{ justifyContent: 'center', paddingTop: '$7', gap: '$2' }}>
          <MomentCalendarButton
            bookmarkId={getBookmarkId()}
            moment={moment}
            variant="primary"
          />
          {showDashboardButton && <DashboardButton href={editMomentHref} />}
        </Flex>
      );
    } else if (momentStatus === 'OPEN' && momentStats.collectorsCount === 0) {
      return null;
    } else {
      return (
        <MomentStatsContainer>
          <MomentStats stats={momentStats} onWorksClick={scrollToWorks} />
        </MomentStatsContainer>
      );
    }
  };

  return (
    <>
      <MomentCreatorsModal creatorsCount={contributorsCount} />
      <HeaderContainer>
        <HeaderContent>
          <Flex
            css={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <WorldTag.Mini world={world} />
            <MomentHeading lineHeight={0} weight="medium">
              {moment.name}
            </MomentHeading>
            <MomentDynamicLabel
              startsAt={moment.startsAt}
              momentStatus={momentStatus}
            />
          </Flex>
          {getCallToAction()}
          {contributorsCount > 0 && (
            <ContributorsContainer>
              <Text
                color="dim"
                size={1}
                lineHeight={2}
                css={{
                  '& div': {
                    display: 'inline',
                  },
                }}
              >
                Works by{' '}
                <Contributors
                  contributors={contributors}
                  contributorsCount={contributorsCount}
                  onMoreClick={() => {
                    modal.setModal({
                      type: 'MOMENT_CREATORS',
                      momentId: moment.id,
                    });
                  }}
                />
              </Text>

              {momentStatus !== 'UPCOMING' && (
                <Flex css={{ gap: '$2' }}>
                  <MomentCalendarButton
                    bookmarkId={getBookmarkId()}
                    moment={moment}
                    variant="primary"
                  />
                  {showDashboardButton && (
                    <DashboardButton href={editMomentHref} />
                  )}
                </Flex>
              )}
            </ContributorsContainer>
          )}
        </HeaderContent>
        <MediaContainer>
          {/**
           * we are fetching the image dimensions on of the poster on the
           * server-side and passing those through to provide an aspect ratio
           * container for the poster image. This avoids layout shift.
           */}
          <AspectRatioBox
            style={{
              aspectRatio: `${imageDimensions.width}/${imageDimensions.height}`,
            }}
          >
            <Image
              src={optimizedAsset}
              alt={moment.name}
              style={{
                display: 'block',
                maxHeight: 600,
              }}
            />
          </AspectRatioBox>
        </MediaContainer>
      </HeaderContainer>
    </>
  );
}

MomentPageHeader.Skeleton = function MomentPageHeaderSkeleton(
  props: MomentPageHeaderProps
) {
  const {
    world,
    moment,
    momentStats,
    contributors,
    contributorsCount,
    imageDimensions,
  } = props;

  const optimizedAsset = optimizeAsset(moment.posterUrl, {
    w: 800,
    auto: undefined,
    dpr: 2,
  });

  const momentStatus = getMomentStatus({
    startsAt: moment.startsAt,
    liveActivityCount: momentStats.liveActivityCount,
    isSoldOut: momentStats.isSoldOut,
  });

  const getCallToAction = () => {
    if (momentStatus === 'UPCOMING') {
      return (
        <Flex css={{ justifyContent: 'center', paddingTop: '$7' }}>
          <Skeleton.Button size={0} />
        </Flex>
      );
    } else if (momentStatus === 'OPEN' && momentStats.collectorsCount === 0) {
      return null;
    } else {
      return (
        <MomentStatsContainer>
          <MomentStats.Skeleton />
        </MomentStatsContainer>
      );
    }
  };

  return (
    <>
      <MomentCreatorsModal creatorsCount={contributorsCount} />
      <HeaderContainer>
        <HeaderContent>
          <Flex
            css={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <WorldTag.Mini world={world} />
            <MomentHeading lineHeight={0} weight="medium">
              {moment.name}
            </MomentHeading>
            <MomentDynamicLabel
              momentStatus={momentStatus}
              startsAt={moment.startsAt}
            />
          </Flex>
          {getCallToAction()}
          {contributorsCount > 0 && (
            <ContributorsContainer>
              <Text
                color="dim"
                size={1}
                lineHeight={2}
                css={{
                  '& div': {
                    display: 'inline',
                  },
                }}
              >
                Works by{' '}
                <Contributors
                  contributors={contributors}
                  contributorsCount={contributorsCount}
                  onMoreClick={noop}
                />
              </Text>
              {momentStatus !== 'UPCOMING' && <Skeleton.Button size={0} />}
            </ContributorsContainer>
          )}
        </HeaderContent>
        <MediaContainer>
          {/**
           * we are fetching the image dimensions on of the poster on the
           * server-side and passing those through to provide an aspect ratio
           * container for the poster image. This avoids layout shift.
           */}
          <AspectRatioBox
            style={{
              aspectRatio: `${imageDimensions.width}/${imageDimensions.height}`,
            }}
          >
            <Image
              src={optimizedAsset}
              alt={moment.name}
              style={{
                display: 'block',
              }}
            />
          </AspectRatioBox>
        </MediaContainer>
      </HeaderContainer>
    </>
  );
};

export function Contributors(props: {
  contributors: UserLight[];
  contributorsCount: number;
  onMoreClick(): void;
}) {
  const initialContributors = props.contributors.slice(0, 3);
  const additionalContributorsCount =
    props.contributorsCount - initialContributors.length;

  const getDisplayName = (user: UserLight) => {
    const usernameOrAddressInfo = getUsernameOrAddressInfo(user);

    if (usernameOrAddressInfo.nameOrUsername) {
      return usernameOrAddressInfo.nameOrUsername;
    } else {
      return usernameOrAddressInfo.publicKey;
    }
  };

  if (additionalContributorsCount > 0) {
    return (
      <>
        {initialContributors.map((item, index, array) => (
          <Fragment key={index}>
            <ProfileHoverCard publicKey={item.publicKey}>
              <NextLink
                passHref
                href={getPath.profile.page(getUsernameOrAddress(item))}
              >
                <Link weight="medium" color="strong">
                  {getDisplayName(item)}
                </Link>
              </NextLink>
            </ProfileHoverCard>
            {getConjuction(index, array, 'comma')}
          </Fragment>
        ))}{' '}
        and{' '}
        <Link
          weight="medium"
          variant="strong"
          as="span"
          onClick={() => {
            props.onMoreClick();
          }}
        >
          {additionalContributorsCount} more
        </Link>
      </>
    );
  } else {
    return initialContributors.map((item, index, array) => (
      <>
        <ProfileHoverCard publicKey={item.publicKey}>
          <NextLink
            passHref
            href={getPath.profile.page(getUsernameOrAddress(item))}
          >
            <Link weight="medium" color="strong">
              {getDisplayName(item)}
            </Link>
          </NextLink>
        </ProfileHoverCard>
        {getConjuction(index, array, 'and')}
      </>
    ));
  }
}

function DashboardButton(props: { href: string }) {
  return (
    <NextLink href={props.href} passHref>
      <Button size={0} as="a" icon>
        <GridIcon />
        <span>Dashboard</span>
      </Button>
    </NextLink>
  );
}

const MomentStatsContainer = styled('div', {
  paddingTop: '$7',
  '@bp0': {
    width: 460,
    paddingTop: '40px',
    marginX: 'auto',
  },
});

const DateText = styled(Text, {
  color: '$black50',
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
});

DateText.defaultProps = {
  size: {
    '@initial': 4,
    '@bp2': 6,
  },
};

const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '$7',
  gap: '$8',
  '@bp2': {
    flexDirection: 'row',
    gap: '$9',
    padding: '$9',
  },
});

const HeaderContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@bp2': {
    width: '50%',
    maxWidth: '540px',
  },
});

const ContributorsContainer = styled('div', {
  paddingTop: '$7',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$4',
  textAlign: 'center',
  '@bp0': {
    paddingTop: '40px',
  },
});

const MediaContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@bp1': {
    width: '80%',
    marginX: 'auto',
  },
  '@bp2': {
    width: '50%',
  },
});

const AspectRatioBox = styled('div', {
  maxHeight: 600,
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
});

const MomentHeading = styled(Heading, {
  paddingTop: '$7',
  textAlign: 'center',
  width: '100%',
  hyphens: 'auto',
  '@bp2': {
    lineHeight: 1,
  },
});

MomentHeading.defaultProps = {
  size: {
    '@initial': 6,
    '@bp2': 7,
  },
};
