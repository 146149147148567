import {
  DropCollectionIcon,
  EditionCollectionIcon,
  GridIcon,
  MomentIcon,
  NftIcon,
  OpenCollectionIcon,
  OpenEditionIcon,
} from '@f8n/icons';

import { ContractCategory, ContractType } from 'types/Collection';
import { Icon } from 'types/icon';
import { ProductCategory } from 'types/product-category';

export const BRAND_ICONS: Record<ProductCategory, Icon> = {
  drop: DropCollectionIcon,
  editionCollection: EditionCollectionIcon,
  editionToken: OpenEditionIcon,
  moment: MomentIcon,
  nft: NftIcon,
  world: GridIcon,
};

const CONTRACT_CATEGORY_ICONS: Record<ContractCategory, Icon> = {
  DROP: DropCollectionIcon,
  EDITION: EditionCollectionIcon,
};

const CONTRACT_TYPE_ICONS: Record<ContractType, Icon | null> = {
  FND_COLLECTION: OpenCollectionIcon,
  FND_BATCH_MINT_REVEAL: DropCollectionIcon,
  HIGHLIGHT_GENERATIVE_SERIES: DropCollectionIcon,
  TIMED_EDITION: EditionCollectionIcon,
  LIMITED_EDITION: EditionCollectionIcon,
  FND: null,
  IMPORTED: null,
  SHARED: null,
};

export const CONTRACT_ICONS: Record<
  ContractType | ContractCategory,
  Icon | null
> = {
  ...CONTRACT_CATEGORY_ICONS,
  ...CONTRACT_TYPE_ICONS,
};
