import { styled } from '@f8n-frontend/stitches';

import Text from 'components/base/Text';

const EthText = styled(Text, {
  display: 'inline-block',
  paddingLeft: '$1',
});

EthText.defaultProps = {
  size: 2,
  color: 'dim',
  weight: 'semibold',
};

export default EthText;
