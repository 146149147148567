import { darkMode, styled } from '@f8n-frontend/stitches';
import { ReactNode } from 'react';

import Box from 'components/base/Box';

import { UserLight } from 'types/Account';

import Text from './base/Text';
import { MemoizedAvatarStack } from './profiles/ProfileCollectors';

const Label = styled(Text, {
  textAlign: 'center',
  whiteSpace: 'nowrap',
  transition: 'color $1 $ease',
  color: '$black60',
  [darkMode]: {
    color: '$black100',
  },
});

Label.defaultProps = {
  size: {
    '@bp0': 1,
    '@initial': 0,
  },
};

/**
 * Use this if the WorldStat block also contains other clickable items as children, like Avatars, or other links.
 */
const ClickableBackground = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const Wrapper = styled('div', {
  position: 'relative',
  paddingX: '$3',
  paddingY: '$2',
  borderRadius: '$2',
  transition: '$2 $ease background',

  '&:after': {
    content: '',
    position: 'absolute',
    top: '$2',
    bottom: '$2',
    right: '-$2',
    width: 1,
    background: '$black10',
    borderRadius: 1,
  },

  '&:last-of-type:after': {
    display: 'none',
  },

  '@bpxs': {
    paddingX: '$2',
    paddingY: '$1',

    '&:after': {
      right: '-$1',
    },
  },

  '@bp0': {
    paddingX: '$5',
    paddingY: '$3',
  },

  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
        '@hover': {
          '&:hover': {
            backgroundColor: '$black5',
            [darkMode]: {
              backgroundColor: '$black10',
            },
          },
          [`&:hover ${Label}`]: {
            color: '$black100',
          },
        },
      },
      false: {
        [`${ClickableBackground}`]: {
          pointerEvents: 'none',
        },
      },
    },
  },
});

const Value = styled(Text, {
  textAlign: 'center',
});

Value.defaultProps = {
  size: {
    '@bp0': 4,
    '@bpxs': 2,
    '@initial': 3,
  },
  color: 'strong',
  weight: 'semibold',
};

type GroupedUsersProps = { users: UserLight[] };

function GroupedUsers(props: GroupedUsersProps) {
  return (
    <Box
      css={{
        marginLeft: '$1',
        position: 'relative',
        zIndex: 2,
      }}
    >
      <MemoizedAvatarStack
        size={{ '@initial': 1, '@bp0': 2 }}
        placement="bottom"
        users={props.users}
        variant="base"
      />
    </Box>
  );
}

type RootProps = { children: ReactNode };

function Root(props: RootProps) {
  return (
    <Box
      css={{
        display: 'grid',
        gap: '$4',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: 'auto',

        borderRadius: '$4',
        backdropFilter: 'blur(20px)',
        padding: '$2',
        marginX: 'auto',
        boxShadow: 'inset 0 0 0 1px $colors$black10',

        [darkMode]: {
          backgroundColor: '$black10',
        },

        '@bpxs': {
          gap: '$2',
        },

        '@bp2': {
          marginX: 'unset',
        },
      }}
    >
      {props.children}
    </Box>
  );
}

const WorldStat = {
  ClickableBackground,
  Root,
  Wrapper,
  Label,
  Value,
  GroupedUsers,
};

export default WorldStat;
