import { styled } from '@f8n-frontend/stitches';
import { useVirtualizer } from '@tanstack/react-virtual';
import NextLink from 'next/link';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import Avatar from 'components/base/Avatar';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Link from 'components/base/Link';
import { MODAL_BODY_IDENTIFIER } from 'components/base/Modal';
import Mono from 'components/base/Mono';
import Text from 'components/base/Text';
import FollowButton from 'components/follows/FollowButton';
import { isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { MODAL_VIRTUALIZED_SCROLL_AREA_HEIGHT } from 'design/tokens/size';
import useFetchVirtualizedData from 'hooks/use-fetch-virtualized-data';
import useModal from 'hooks/use-modal';
import { DataWithAggregate, getAggregateCount } from 'utils/aggregate';
import { getUsernameOrAddressInfo, isBooleanType } from 'utils/helpers';

import { UserLight } from 'types/Account';

type UserListUser = UserLight & {
  isFollowingUser?: DataWithAggregate | boolean;
};

interface VirtualizedUserListProps {
  users: UserListUser[];
  modalTab?: string;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  handleNextPage: () => void;
  onFollowUpdate: () => void;
  title?: ReactNode;
}

export default function VirtualizedUserList(props: VirtualizedUserListProps) {
  const {
    handleNextPage,
    onFollowUpdate,
    isFetchingNextPage,
    hasNextPage,
    users,
    title,
  } = props;

  const [height, setHeight] = useState(MODAL_VIRTUALIZED_SCROLL_AREA_HEIGHT);

  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: users.length,
    getScrollElement: () => parentRef.current,
    // height of the individual elements
    estimateSize: useCallback(() => 72, []),
    // buffer to keep extra items below the fold
    overscan: 20,
  });

  // This is a hack to get the height of a parent tab,
  // and make sure the virtualized list fills the height
  // By adding a hack here, we simplify the rest of our Modal code
  useEffect(() => {
    if (!parentRef.current) return;
    const parentEl = parentRef.current.parentElement;
    if (!parentEl) return;

    // If the parent is a Tab item
    if (parentEl.getAttribute('role') === 'tabpanel') {
      setHeight(parentEl.getBoundingClientRect().height);
    }

    // If the parent is a Modal body
    if (parentEl.dataset.modalEl === MODAL_BODY_IDENTIFIER) {
      setHeight(parentEl.getBoundingClientRect().height);
    }
  }, []);

  useFetchVirtualizedData({
    data: users,
    virtualItems: rowVirtualizer.getVirtualItems(),
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage: handleNextPage,
  });

  return (
    <VirtualizedItemsScrollArea
      style={{
        minHeight: height,
        maxHeight: height,
      }}
      ref={parentRef}
    >
      {title}
      <VirtualizedItemsContainer
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const userRow = users[virtualRow.index];

          if (!userRow) return null;

          // isFollowingUser is a number from Hasura, vs. a boolean from the API
          const isInitiallyFollowing = isBooleanType(userRow.isFollowingUser)
            ? userRow.isFollowingUser
            : userRow.isFollowingUser !== undefined
              ? getAggregateCount(userRow.isFollowingUser) > 0
              : undefined;

          return (
            <VirtualizedItem
              key={virtualRow.index}
              style={{
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <UserRow
                user={userRow}
                isInitiallyFollowing={isInitiallyFollowing}
                onFollowUpdate={onFollowUpdate}
              />
            </VirtualizedItem>
          );
        })}
      </VirtualizedItemsContainer>
    </VirtualizedItemsScrollArea>
  );
}

const VirtualizedItemsScrollArea = styled('div', {
  paddingY: '$2',
  maxWidth: 760,
  overflow: 'auto',
  position: 'relative',
  '@bp0': { paddingX: '$2' },
});

const VirtualizedItemsContainer = styled('div', {
  width: '100%',
  position: 'relative',
});

const VirtualizedItem = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  display: 'flex',
});

interface UserRowProps {
  user: UserLight;
  onFollowUpdate: () => void;
  isInitiallyFollowing: boolean | undefined;
}

function UserRow(props: UserRowProps) {
  const { user, isInitiallyFollowing, onFollowUpdate } = props;

  const auth = useAuth();
  const { setModal } = useModal();

  const publicKey = user.publicKey;

  const isMyProfile = isMyAddress(auth, publicKey);

  const { userPath, nameOrUsername, usernameOrAddress } =
    getUsernameOrAddressInfo(user);

  return (
    <Row>
      <NextLink href={`/${userPath}`} prefetch={false} passHref>
        <AvatarRowLink onClick={() => setModal(null)}>
          <Flex css={{ marginRight: '$3' }}>
            <Avatar
              publicKey={publicKey}
              imageUrl={user.profileImageUrl}
              size={{ '@initial': 3, '@bp2': 4 }}
              alt={nameOrUsername}
            />
          </Flex>
          <Box css={{ flex: 1, minWidth: 0 }}>
            {nameOrUsername ? (
              <>
                <Heading color="strong" size={2} ellipsis>
                  {nameOrUsername}
                </Heading>
                <Text size={1} color="dim" ellipsis>
                  {usernameOrAddress}
                </Text>
              </>
            ) : (
              <Mono size={3} css={{ color: '$black100' }}>
                {usernameOrAddress}
              </Mono>
            )}
          </Box>
        </AvatarRowLink>
      </NextLink>
      {!isMyProfile && (
        <FollowButton
          onSuccess={onFollowUpdate}
          publicKey={publicKey}
          isInitiallyFollowing={isInitiallyFollowing}
          size={0}
        />
      )}
    </Row>
  );
}

const Row = styled(Flex, {
  flex: 1,
  alignItems: 'center',
  transition: 'background $0 ease',
  paddingX: '$3',
  borderRadius: '$3',
  gap: '$3',
  maxWidth: '100%',

  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: '$black5',
    },
  },
});

const AvatarRowLink = styled(Link, {
  flex: 1,
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',

  '&:focus': {
    outline: 'none',
  },
});
