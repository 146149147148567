import { match } from 'ts-pattern';

import { ApiEditionSaleConfigurationFragment } from 'gql/api/api-fragments.generated';

import { EditionCollectionSale } from 'types/EditionSale';

type EditionSaleByType<Type extends EditionCollectionSale['type']> = Extract<
  EditionCollectionSale,
  { type: Type }
>;

/**
 * @param saleConfiguration a union of all possible sale configurations on the ApiEditionFragment
 * @returns a normalized EditionCollectionSale object or null if no sale configuration is present
 *
 * Note that due to limitations with GraphQL unions, some data passed in via the saleConfigurationUnion has aliased fields
 * like `optionalEndTime`. This function normalizes those aliased fields to the expected field names.
 */
export const mapEditionSaleConfigurationToEditionSale = (
  saleConfiguration: ApiEditionSaleConfigurationFragment
): EditionCollectionSale => {
  return match(saleConfiguration)
    .with(
      { __typename: 'LimitedEditionSaleConfiguration' },
      (sale): EditionSaleByType<'LIMITED_EDITION'> => {
        return {
          type: 'LIMITED_EDITION',
          ...sale,
          status: sale.limitedEditionStatus,
        };
      }
    )
    .with(
      { __typename: 'TimedEditionSaleConfiguration' },
      (sale): EditionSaleByType<'TIMED_EDITION'> => {
        return {
          type: 'TIMED_EDITION',
          ...sale,
          status: sale.timedEditionStatus,
        };
      }
    )
    .otherwise(() => {
      throw new Error('Unknown edition sale configuration type');
    });
};
